@import '../../styles/colors.scss';

.shopping-list-item {
  display: flex;
  align-items: center;
}

.shopping-list-item .item {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.shopping-list-item button {
  background: transparent;
  border: none;
  padding: 0px;
  color: #ffffff;
}

.input-container {
  display: flex;
  padding-bottom: 12px;
}

.item-input-field {
  padding: 0 12px;
  width: 100%;
}

.shopping-list-item button svg {
  /* icon by https://fontawesome.com/ */
  height: 20px;
  width: 24px;
}

.shopping-list-item .actions {
  margin-left: auto;
  padding-top: 8px;
  padding-bottom: 8px;  
}

.shopping-list-item .dnd {
  touch-action: none;
  padding-right: 12px;
  cursor: grab;
  padding-top: 7px;
  padding-bottom: 7px;
}

.shopping-list-item .dnd[aria-pressed="true"]{
  cursor: grabbing;
}

.shopping-list-item span {
  color: #ffffff;
  padding: 6px 0;
  font-size: 110%;
  line-height: 24px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  cursor: pointer;
}

.shopping-list-item span.done {
  text-decoration: line-through;
  text-decoration-thickness: 3px;
}

.react-contexify {
  min-width: 100px;
}