@import '../../styles/colors.scss';

button.delete {
  border: none;
  height: 36px;
  width: 100%;
  align-self: center;

  background: linear-gradient(to right, $deleteButtonHoldColor 50%, $deleteButtonColor 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 1s ease-out;
}

button.deleting {
  background-position: left bottom;
}

@keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}