@import './reset.scss';
@import './colors.scss';

html, body {
  height: 100%;
  //background: #2c5364;
}

body {
  // Space gray
  //background: #0f2027;
  background: -webkit-linear-gradient(180deg, #0f2027, #203a43, #2c5364);
  background: linear-gradient(180deg, #0f2027, #203a43, #2c5364);

  background-repeat: no-repeat;
  background-attachment: fixed;

  font-family: "Roboto", serif;
  padding: 0;
  color: $primaryTextColor;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

p::-moz-selection { background: $secondaryTextColor}
p::selection { background: $secondaryTextColor }

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#content {
  flex: 1 0 auto;
}

h1 {
  font-family: "Delius", cursive;
  font-size: 180%;
  color: $primaryTextColor;
}

h2 {
  font-size: 150%;
  color: $secondaryTextColor;
}

h3 {
  color: $primaryTextColor;
}

a {
  color: $primaryLinkColor;
}

a:focus, a:hover {
  color: $primaryLinkFocusColor;
}

a.secondary {
  color: $secondaryLinkColor;
}

a.secondary:focus, a.secondary:hover {
  color: $secondaryLinkFocusColor;
}

input {
  height: 32px;
  margin: 0;
  border: 2px solid $inputBorderColor;
  border-radius: 6px;
  background: $inputBackgroundColor;
  color: $inputTextColor;
}

input:focus, input:hover  {
  border: 2px solid $inputFocusBorderColor;
}

input:disabled,
input[disabled] {
  background: $disabledInputBackgroundColor;
  color: $disabledInputTextColor;
  -webkit-text-fill-color: $disabledInputTextColor;
  opacity: 1; /* required on iOS */
  border: 1px solid $disabledInputTextColor;
}

::placeholder {
  color: $inputPlaceholderTextColor;
}

button {
  font-family: "Roboto", serif;
  border-radius: 6px;
  font-size: 100%;
  height: 38px;
  border: 1px solid $primaryButtonBoarderColor;
  background-color: $primaryButtonColor;
  color: $primaryButtonTextColor;
  padding: 0px 20px 2px 20px;
}

button:focus, button:hover {
  background-color: $primaryButtonFocusColor;
  text-decoration: underline;
}

button:disabled,
button[disabled]{
  border: 1px solid $disabledButtonColor;
  background-color: $disabledButtonColor;
  color: $disabledButtonTextColor;
  text-decoration: none;
}


button svg.button-spinner {
  height: 20px;
  animation: spin 2.5s linear infinite;
  align-self: center;
  margin-left: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% {  transform: rotate(359deg); }
}

.logo-img {
  height: 200px;
}

.main-content-with-menu {
  padding: 75px 15px 15px 15px;
}

.center-container {
  padding: 6vw;
  margin: auto;
}

.center-container-with-menu {
  padding: 85px 25px;
  margin: auto;
}

.narrow-page-container {
  padding: 25px 25px 40px 25px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  background-color: $backgroundColor;
  
  border-radius: 10px;
}

.narrow-page-container form {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.narrow-page-container h1 {
  margin-bottom: 30px;
}

.narrow-page-container input {
  margin-bottom: 20px;
  padding-left: 10px;
  align-self: stretch;
}

.narrow-page-container .submit {
  width: 100%;
}

.narrow-page-container .secondary-link {
  padding-top: 25px;
}

.narrow-page-container .thirdary-link {
  padding-top: 35px;
}

.narrow-page-container span {
  color: $secondaryTextColor;
}

.narrow-page-container p {
  text-align:center;
}

footer {
  font-size: small;
  flex-shrink: 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $footerTextColor;
}

footer p {
  text-align: center;
}

footer a {
  color: $footerTextColor;
}