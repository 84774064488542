@import '../../styles/colors.scss';

.main-menu {
  background-color: $menuBackgroundColor;
  position: fixed;
  width: 100%;
  z-index: 3;
  display: block;
}

.main-menu * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.main-menu .logo {
  font-family: "Delius", cursive;
  font-size: 1.5em;
  display: flex;
  float: left;
  padding: 15px;
  text-decoration: none;
  color: $menuTextColor;
  align-items: flex-end;
}

.main-menu .logo-head-img {
  height: 26px;
  padding-right: 15px;
}

.main-menu a svg {
  padding-left: 10px;
  padding-top: 1px;
  height: 17px;
}


.main-menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}


.menu-item {
  display: flex;
  margin-left: auto;
  align-self: center;
  align-items: center;
  padding: 20px 20px;
  text-decoration: none;
  color: yellow;
}

.main-menu .menu {
  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
}

.main-menu .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 28px 15px;
  position: relative;
  user-select: none;
}

.main-menu .menu-icon .navicon {
  background: $menuItemTextColor;
  display: block;
  height: 4px;
  position: relative;
  transition: background .2s ease-out;
  width: 32px;
}

.main-menu .menu-icon .navicon:before,
.main-menu .menu-icon .navicon:after {
  background: $menuItemTextColor;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.main-menu .menu-icon .navicon:before {
  top: 8px;
}

.main-menu .menu-icon .navicon:after {
  top: -8px;
}

.main-menu .menu-btn {
  display: none;
}

.main-menu .menu-btn ~ .menu {
  height: 0vh;
  background-color: transparent; //TODO figure out what do do with this color.
  transition: all .05s ease;
}

.main-menu .menu-btn:checked ~ .menu {
  max-height: 100%;
  height: 100vh;
}

.main-menu .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.main-menu .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.main-menu .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.main-menu .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.main-menu .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* default menu (all screens without disktop menu overides */
.main-menu li {
  height: 58px;
  margin: 0 30px;
  border-bottom: 2px solid $secondaryTextColor;
}

.main-menu li a {
  display: flex;
  justify-content: center;
  padding: 20px 20px;
  text-decoration: none;
  color: $primaryNavigationColor;
}

.main-menu li a.active {
  color: $menuActiveItemTextColor;
}

.main-menu li a:hover, .main-menu .menu-btn:hover {
  text-decoration: underline;
}

/* 48em = 768px */
/* Menu for big screens */
@media (min-width: 48em) {
  
  .main-menu li {
    float: left;
    border-bottom: none;
    margin: 0;
  }

  .main-menu li a {
    padding: 20px 30px;
  }

  .main-menu .menu {
    clear: none;
    float: right;
    max-height: none;
  }

  /* hide hambuger menu */
  .main-menu .menu-icon {
    display: none;
  }

  /* override desktop menu even though it is "checked" */
  .main-menu .menu-btn ~ .menu {
    height: 100% !important;
    background-color: transparent !important;
  }
}