
@import '../../styles/colors.scss';

.profile-page .avatar-container img {
  height: 200px; 
  border-radius: 20%;
}

.profile-page h2 {
  margin: 20px 0;
}

.profile-page span {
  display: flex;
  align-items: center;
}

.profile-page-element {
  width: 100%;
  margin-bottom: 35px;
}

.profile-page-element span {
  color: $primaryTextColor;
  padding-bottom: 10px;
}

.profile-page-element input {
  flex-grow: 1;
  margin-bottom: 0;
}

.profile-page-element div {
  display:flex;
}

.profile-page-element div button {
  margin-left: 10px;
}

.profile-page span a {
  padding-left: 5px;
}

.delete-account {
  display: flex;
  flex-direction: column;
  align-self: stretch;
}

.delete-account h2 {
  margin-top: 30px;
  margin-bottom: 20px;
  text-align: center;
}

.delete-account input {
  margin-bottom: 20px;
  padding-left: 10px;
  align-self: stretch;
}

.button-container {
  display: flex;
}

.delete-account button {
  width: 100%;
}