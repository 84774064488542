@import '../../styles/colors.scss';

.app-header {
  background-color: $headerColor;
  position: fixed;
  width: 100%;
  z-index: 3;
  display: flex;
  align-items: center;
  height: 60px;
}

.app-header .icon {
  display: block;
  font-size: 1.5em;
  padding: 0 10px;
  text-decoration: none;
  color: $primaryNavigationColor;
  width: 32px;
}

.app-header .next-page-icon {
  margin-left: auto;
}

.app-header a svg {
  height: 30px;
}

.app-header h2 {
  color: $headerTextColor;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 32px;
  flex-grow: 1;
  text-align: center;
}