@import '../../styles/colors.scss';

.create-item-conteiner {
  display: inherit;
  align-items: center;
  padding-bottom: 12px;
}

.create-item-conteiner input {
  padding: 0 0 0 12px;
  width: 100%;
  border: none;
}

.create-item-conteiner svg {
  height: 24px;
  padding-left: 12px;
  color: #ffffff;
}
