@import '../../styles/colors.scss';

.my-lists-container {
  max-width: 600px;
  margin: 0 auto;
}

.my-lists-container ul {
  width: 100%;
}

.my-lists-container li {
  margin-bottom: 15px;
  //background-color: $listBackgroundColor;
  background-color: white;
  border: 1px solid $listBackgroundColor;
  border-radius: 10px;
  display: flex;
}

.my-lists-container li:hover {
  border: 1px solid $listHoverBorderColor;
}

.my-lists-container .float-right {
  display: flex;
  margin-left: auto;
}

.my-lists-container a {
  display: flex;
  color: $primaryTextColor;
  text-decoration: none;
  font-size: 1.2em;
  align-self: center;
  width: 100%;
  height: 100%;
  padding: 10px;
}

.my-lists-container a:hover {
  text-decoration: none;
}


.my-lists-container .item-container  {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.my-lists-container .item-container .list-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.my-lists-container .item-container .updated-at {
  color: #333333;
  font-size: 12px;
  border-radius: 15px;
  background: #eeeeee;
  padding: 5px;
}

.my-lists-container .item-container .shared_with {
  vertical-align: middle;
  height: 22px;
  border-radius: 50%;
  margin-right: -4px;
  margin-top: 8px;
  border: 2px white solid;
  margin-bottom: -2px;
}

.my-lists-container .item-container .shared_with:first-of-type {
  border: none
}
/*
.my-lists-container span.content {
  display: block;
  font-size: 1.3em;
  padding-top: 25px;
  padding-bottom: 25px;
  line-height: 140%;
  text-align: center;
}
*/

.my-lists-container .list-name {
  font-weight: 500;
}

.new-list-container {
  display: flex;
  justify-content: center;
}