// 2024 light
$backgroundColor: #ffffff; //

// Text color
$primaryTextColor: #2b5161; //
$secondaryTextColor: #797979; //

// Links
$primaryLinkColor: #0499f6; // 
$primaryLinkFocusColor: #0c85d1; //
$secondaryLinkColor: $primaryLinkColor;
$secondaryLinkFocusColor: $primaryLinkFocusColor;

// Buttons
$primaryButtonColor: #2c5364; //
$primaryButtonFocusColor: #213b45; //
$primaryButtonBoarderColor: #213b45;
$primaryButtonTextColor: $backgroundColor;

$primaryButtonColorDarkBackground: #ffffff; //
$primaryButtonFocusColorDarkBackground: #dddddd; //
$primaryButtonBoarderColorDarkBackground: #acb3b9;
$primaryButtonTextColorDarkBackground: $primaryTextColor;

// Navigation, headers and menus
$primaryNavigationColor: white; //
$headerColor: #2c5364; // improve 2c5364
$headerTextColor: white;
$menuBackgroundColor: #2c5364; // improve
$menuTextColor: #ffffff;
$menuItemTextColor: white;
$menuActiveItemTextColor: white; // fix menu high lighting later

// Forms
$inputBackgroundColor: #ffffff;
$inputBorderColor: #acb3b9;
$inputFocusBorderColor: #84898d;
$inputTextColor: #555555;
$inputPlaceholderTextColor: #a0a8ae; 

$disabledInputBackgroundColor: #dedede; // ok
$disabledInputTextColor: #747475; // ok
$disabledButtonColor: #dedede; // ok
$disabledButtonTextColor: #747475; // ok

// delete button colors
$deleteButtonColor: $primaryButtonColor; // ok
$deleteButtonHoldColor: #da2c38; // ok

// buttons where side effect will be much less
$removeButtonColor: rgba(255, 255, 255, 0.5); // ok
$removeButtonTextColor: #0e2129; // ok
$removeButtonHoldColor: rgba(255, 255, 255, 0.9); //ok

// List Background
$listBackgroundColor: #0e2129;
$listHoverBorderColor: $secondaryTextColor;

$footerTextColor: #ffffff;

// Original deep blue
/*
$backgroundColor: #0B132B;
$primaryTextColor: #EEEEEE;
$secondaryTextColor: rgba(255, 255, 255, 0.5);

$primaryLinkColor: #5dc1bf;
$primaryLinkFocusColor: yellow;
$secondaryLinkColor: rgba(255, 255, 255, 0.5);
$secondaryLinkFocusColor: $primaryLinkFocusColor;

$primaryButtonColor: #5dc1bf;
$primaryButtonFocusColor: $primaryButtonColor; // fix later
$primaryButtonTextColor: #2f0341;
$primaryButtonBoarderColor: $primaryButtonColor; // fix later


// Navigation, headers and menus
$primaryNavigationColor: white;
$headerColor: #2f0341;
$headerTextColor: white;
$menuBackgroundColor: #2f0341;
$menuTextColor: #5dc1bf;
$menuItemTextColor: white;
$menuActiveItemTextColor: #5dc1bf;

// Forms
$inputBackgroundColor: #EEEEEE;
$inputBorderColor: yellow;
$inputFocusBorderColor: yellow;
$inputTextColor: #2f0341;
$inputPlaceholderTextColor: #7e4091cd;

$disabledInputBackgroundColor: rgba(255, 255, 255, 0.3);
$disabledInputTextColor: $secondaryTextColor;
$disabledButtonColor: rgba(255, 255, 255, 0.3);
$disabledButtonTextColor: #2f0341;

// delete button colors
$deleteButtonColor: $primaryButtonColor;
$deleteButtonHoldColor: rgba(255, 255, 255, 0.9);

// buttons where side effect will be much less
$removeButtonColor: rgba(255, 255, 255, 0.5);
$removeButtonTextColor: #2f0341;
$removeButtonHoldColor: rgba(255, 255, 255, 0.9);

// List Background
$listBackgroundColor: #2f0341;
$listHoverBorderColor: $secondaryTextColor;

$footerTextColor: $primaryTextColor;
*/