@import '../../styles/colors.scss';

.list-container {
  display: flex;
  flex-direction: column;
}

.done-list {
  color: $secondaryTextColor;
}

.done-list hr {
  margin: 25px 0;
  border: 1px solid $secondaryTextColor;
}

.done-list h2 {
  padding-top: 30px;
  padding-bottom: 20px;
}

.done-list button.delete {
  margin-top: 10px;
  color: $removeButtonTextColor;
  width: auto;

  background: linear-gradient(to right, $removeButtonHoldColor 50%, $removeButtonColor 50%);
  background-size: 200% 100%;
  background-position: right bottom;
}

.done-list button.deleting {
  background-position: left bottom;
}
