
.list-settings-page-container {
  line-height: initial;
}

.list-settings-page-container h2{
  padding-bottom: 25px;
}

.list-settings-page-container .share {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}