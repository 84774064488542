@import '../../styles/colors.scss';

.new-list-button {
  margin-top: 15px;
  background: $primaryButtonColorDarkBackground;
  border: 1px solid $primaryButtonBoarderColorDarkBackground;
  color: $primaryButtonTextColorDarkBackground;
}

.new-list-button:focus, .new-list-button:hover{
  background: $primaryButtonFocusColorDarkBackground;
}
